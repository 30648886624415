// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-activities-js": () => import("./../../../src/pages/activities.js" /* webpackChunkName: "component---src-pages-activities-js" */),
  "component---src-pages-anatomy-of-a-hit-js": () => import("./../../../src/pages/anatomy-of-a-hit.js" /* webpackChunkName: "component---src-pages-anatomy-of-a-hit-js" */),
  "component---src-pages-career-profiles-js": () => import("./../../../src/pages/career-profiles.js" /* webpackChunkName: "component---src-pages-career-profiles-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-steam-country-js": () => import("./../../../src/pages/STEAMCountry.js" /* webpackChunkName: "component---src-pages-steam-country-js" */),
  "component---src-pages-virtual-field-trip-js": () => import("./../../../src/pages/virtual-field-trip.js" /* webpackChunkName: "component---src-pages-virtual-field-trip-js" */)
}

